import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import IconCustom from 'components/helpers/IconCustom';

import { IButtonProps } from './models';

import './Button.scss';

const Button: FC<IButtonProps> = ({
  ariaLabel,
  children,
  link,
  variant,
  className,
  clickHandler,
  type = 'button',
  target,
  arrowDirection,
  textAlign,
  disabled,
}) => {
  const classesButton = classNames(
    `button`,
    {
      [`button--${variant}`]: variant,
      [`text-align-${textAlign}`]: textAlign,
    },
    className
  );

  if (link) {
    const isInternal = /^\/(?!\/)/.test(link);

    return isInternal ? (
      <Link
        data-testid="ButtonGatsbyLink"
        rel="preload"
        className={classesButton}
        aria-label={ariaLabel}
        to={link}
      >
        {children}
      </Link>
    ) : (
      <a
        data-testid="ButtonLink"
        className={classesButton}
        aria-label={ariaLabel}
        href={link}
        rel="noopener noreferrer"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(target && { target })}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      aria-label={ariaLabel}
      data-testid="Button"
      onClick={clickHandler}
      className={classesButton}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
    >
      {arrowDirection === 'left' ? (
        <IconCustom icon="arrow-left" className="icon-button left" />
      ) : null}
      {children}
      {arrowDirection === 'right' ? (
        <IconCustom icon="arrow-right" className="icon-button right" />
      ) : null}
    </button>
  );
};

export default Button;
