import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import Button from 'components/elements/Button';
import Title from 'components/elements/Title';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import GatsbyImage from 'components/helpers/GatsbyImage';
import findByLang from 'utils/findByLang';

import { INotFoundQueryResult } from './models';

import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();
  const { isMobile } = useScreenRecognition();

  const {
    allNotFoundPage: { nodes },
  }: INotFoundQueryResult = useStaticQuery(graphql`
    {
      allNotFoundPage {
        nodes {
          lang
          text
          linkAriaLabel
          title
          title404
          link {
            url
            name
          }
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 380, fit: CONTAIN) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          urls {
            ...FragmentUrlType
          }
          seo {
            ...SEOStructureFragment
          }
        }
      }
    }
  `);

  const result = nodes.find(({ lang }) => findByLang(lang, currentLangCode));

  return (
    <Layout seo={result?.seo} lang={currentLangCode} urls={result?.urls} className="not-found">
      <Container className="not-found__container">
        <div data-testid="NotFoundPage" className="not-found__part">
          <div>
            {result?.title404 ? (
              <Title className="not-found__title404" Tag="h1">
                {result.title404}
              </Title>
            ) : null}

            {result?.title ? (
              <Title className="not-found__title" Tag="h2">
                {result.title}
              </Title>
            ) : null}

            {result?.text ? (
              <DangerouslySetInnerHtml html={result.text} className="not-found__text" />
            ) : null}

            {result?.link?.[0]?.url && result.link[0]?.name && result.linkAriaLabel ? (
              <div className="not-found__btn-wrapper">
                <Button
                  textAlign="center"
                  variant="inverse"
                  link={result.link[0].url}
                  ariaLabel={result.linkAriaLabel}
                >
                  {result.link[0].name}
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {!isMobile ? (
          <div className="not-found__part">
            {result?.image ? <GatsbyImage image={result.image} /> : null}
          </div>
        ) : null}
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
